<template>
  <b-card
    no-body
  >
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group
            class="mr-1 mb-md-0"
          >
            <b-button
              v-b-modal.modal-1
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-primary"
              block
              @click="addData"
            >
              เพิ่มบัญชีถอน SCB
            </b-button>
            <b-button
              v-if="UserData.sub == 1"
              v-b-modal.modal-1
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-info"
              block
              @click="addDataSub"
            >
              เพิ่มบัญชีถอน SCB ( เฉพาะซัพพอท )
            </b-button>
          </b-form-group>
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              @click="deleteData(data.item.id)"
            />
            <b-tooltip
              title="Preview Invoice"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

      <b-modal
        ref="my-modal"
        title="กรุณากรอกข้อมูล"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="GetDeviceIdScb()"
      >
        <div v-if="step == 1">
          <b-form-group>
            <label for="web">เลือกประเทศ</label>
            <v-select
              v-model="nation"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :options="nlist"
              :reduce="text => text.value"
              class="bg-white rounded"
              placeholder="เลือกประเทศ"
            />
          </b-form-group>

          <b-form-group>
            <label for="dividend">ชื่อบัญชี <span class="text-danger">* ชื่อ - นามสกุลเจ้าของบัญชี </span></label>
            <b-input-group>
              <b-form-input
                v-model="StoreForm.accname"
                type="text"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend">เลขบัตรประชาชน <span class="text-danger">* ไม่มี - </span></label>
            <b-input-group
              prepend="ไม่ต้องมี -"
            >
              <b-form-input
                v-model="StoreForm.card_id"
                type="text"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend">ปีเกิด (คศ.)-เดือน-วัน<span class="text-danger">*(ตัวอย่าง 1999-01-01)</span></label>
            <b-input-group
              prepend="1999-01-01"
            >
              <b-form-input
                v-model="StoreForm.date_of_brith"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend">เบอร์โทร <span class="text-danger">* ไม่มี -</span></label>
            <b-input-group
              prepend="ไม่ต้องมี -"
            >
              <b-form-input
                v-model="StoreForm.phone"
                type="number"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div v-if="step == 2">
          <b-form-group>
            <label for="dividend"> เลขที่บัญชี <span class="text-danger">* ไม่มี -</span></label>
            <b-input-group
              prepend=""
            >
              <b-form-input
                v-model="StoreForm.accno"
                type="number"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend"> PIN <span class="text-danger">*</span></label>
            <b-input-group
              prepend=""
            >
              <b-form-input
                v-model="StoreForm.pin"
                type="number"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend"> OTP <span class="text-danger">*  (ไม่เกิน 5 นาที)</span></label>
            <b-input-group
              prepend=""
            >
              <b-form-input
                v-model="StoreForm.otp"
                type="number"
              />
            </b-input-group>
          </b-form-group>
        </div>
      </b-modal>

      <b-modal
        ref="my-modal-sub"
        title="กรุณากรอกข้อมูล"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="GetDeviceIdScbSub()"
      >
        <b-form-group>
          <label for="dividend"> เลขที่บัญชี <span class="text-danger">* ไม่มี -</span></label>
          <b-input-group
            prepend=""
          >
            <b-form-input
              v-model="sub.accno"
              type="number"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <label for="dividend">ชื่อบัญชี <span class="text-danger">* ชื่อ - นามสกุลเจ้าของบัญชี </span></label>
          <b-input-group>
            <b-form-input
              v-model="sub.accname"
              type="text"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <label for="dividend">เลขบัตรประชาชน <span class="text-danger">* ไม่มี - </span></label>
          <b-input-group
            prepend="ไม่ต้องมี -"
          >
            <b-form-input
              v-model="sub.card_id"
              type="text"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <label for="dividend">ปีเกิด (คศ.)-เดือน-วัน<span class="text-danger">*(ตัวอย่าง 1999-01-01)</span></label>
          <b-input-group
            prepend="1999-01-01"
          >
            <b-form-input
              v-model="sub.date_of_brith"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <label for="dividend">เบอร์โทร <span class="text-danger">* ไม่มี -</span></label>
          <b-input-group
            prepend="ไม่ต้องมี -"
          >
            <b-form-input
              v-model="sub.phone"
              type="number"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <label for="dividend"> PIN ( ต้องเปลี่ยนใหม่หลัง Gen ) <span class="text-danger">*</span></label>
          <b-input-group
            prepend=""
          >
            <b-form-input
              v-model="sub.pin"
              type="number"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <label for="dividend"> DeviceId <span class="text-danger">*</span></label>
          <b-input-group
            prepend=""
          >
            <b-form-input
              v-model="sub.device_id"
              type="text"
            />
          </b-input-group>
        </b-form-group>

      </b-modal>
    </b-overlay>
  </b-card>

</template>

<script>
// import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  BModal, BCard, BTable,
  BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput,
  BInputGroupAppend, BButton, BCardBody, VBToggle,
  BOverlay, BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,

    BModal,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      depositdata: [],
      bankData: '',
      bankname: '',
      bankcode: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'accno', label: 'เลขบัญชี' },
        { key: 'accname', label: 'ชื่อ-สกุล' },
        { key: 'phone', label: 'เบอร์มือถือ' },
        // { key: 'phone', label: 'เบอร์มือถือ' },
        { key: 'actions', label: 'Action' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      nation: '',
      nlist: [
        { value: '', text: 'ประเทศไทย' },
        { value: 'MM', text: 'พม่า' },
        { value: 'LA', text: 'ลาว' },
        { value: 'KH', text: 'กัมพูชา' },
        { value: 'VN', text: 'เวียดนาม' },
        { value: 'ID', text: 'อินโดนีเซีย' },
        { value: 'SG', text: 'สิงคโปร์' },
      ],
      StoreForm: {
        ID: null,
        card_id: '',
        date_of_brith: '',
        phone: '',

        accname: '',
        accno: '',
        pin: '',
        otp: '',
        device_id: null,
        Auth: null,
        ref: null,
        tokenUUID: null,
        bank: 'scb',
      },
      sub: {
        accno: '',
        accname: '',
        card_id: '',
        date_of_brith: '',
        phone: '',
        pin: '',
        device_id: '',
      },
      step: 1,
      show: false,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http
        .get('/setting/withdrawscb/index')
        .then(response => {
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    addData() {
      this.$refs['my-modal'].show()
    },
    addDataSub() {
      this.$refs['my-modal-sub'].show()
    },
    GetDeviceIdScb() {
      this.show = true
      if (this.step === 1) {
        if (this.nation) {
          this.StoreForm.card_id = this.nation + this.StoreForm.card_id
          // eslint-disable-next-line vars-on-top, no-var, block-scoped-var
          var Type = 'P8'
        } else {
          // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-redeclare
          var Type = 'P1'
        }
        const obj = {
          cardId: this.StoreForm.card_id,
          dateOfBirth: this.StoreForm.date_of_brith,
          MobilePhoneNo: this.StoreForm.phone,
          // eslint-disable-next-line block-scoped-var
          type: Type,
        }
        this.$http
          .post('/setting/withdrawscb/deviceid', obj)
          .then(response => {
            // console.log(response.data)
            if (response.data.Auth) {
              this.show = false
              this.step = 2
              this.StoreForm.ID = response.data.ID
              this.StoreForm.device_id = response.data.deviceId
              this.StoreForm.Auth = response.data.Auth
              this.StoreForm.ref = response.data.ref
              this.StoreForm.tokenUUID = response.data.tokenUUID
              this.addData()
            }
          })
          .catch(error => {
            this.show = false
            if (error.response.data.message.statusdesc) {
              this.SwalError(error.response.data.message.statusdesc)
            } else {
              this.SwalError(error.response.data.message)
            }

            this.addData()
          })
      } else {
        const obj = {
          ID: this.StoreForm.ID,
          acc_name_scb: this.StoreForm.accname,
          acc_no: this.StoreForm.accno,
          bank: this.StoreForm.bank,
          phone: this.StoreForm.phone,
          pin: this.StoreForm.pin,
          otp: this.StoreForm.otp,
          deviceId: this.StoreForm.device_id,
          Auth: this.StoreForm.Auth,
          ref: this.StoreForm.ref,
          tokenUUID: this.StoreForm.tokenUUID,
        }
        this.$http
          .post('/setting/withdrawscb/confirmotp', obj)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            // console.log(response.data)
            this.getData()
            this.Success('เพิ่มข้อมูลโยกเงินสำเร็จ')
            this.acc_name_scb = ''
            this.deviceId = ''
            this.Auth = ''
            this.ref = ''
            this.tokenUUID = ''
            this.MobilePhoneNo = ''
            this.pin = ''
            this.otp = ''
            this.cardId = ''
            this.dateOfBirth = ''
            this.acc_no = ''
            this.show = false
            this.step = 1
          })
          .catch(error => {
            this.step = 1
            if (error.response.data.message.statusdesc) {
              this.SwalError(error.response.data.message.statusdesc)
            } else {
              this.SwalError(error.response.data.message)
            }
            this.showModal()
            this.show = false
          })
      }
    },
    GetDeviceIdScbSub() {
      this.show = true
      const obj = {
        accno: this.sub.accno,
        accname: this.sub.accname,
        date_of_birth: this.sub.date_of_brith,
        card_id: this.sub.card_id,
        phone: this.sub.phone,
        pin: this.sub.pin,
        device_id: this.sub.device_id,
      }
      this.$http
        .post('/setting/withdrawscb/AddBySubport', obj)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
          this.getData()
          this.Success('เพิ่มข้อมูลสำเร็จ')
          this.show = false
          this.sub = {
            accno: '',
            accname: '',
            card_id: '',
            date_of_brith: '',
            phone: '',
            pin: '',
            device_id: '',
          }
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
          this.addDataSub()
          this.show = false
        })
    },
    deleteData(id) {
      this.$swal({
        title: 'ลบบัญชี?',
        text: 'กรุณายืนยันการลบบัญชี',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const params = {
            id,
          }
          this.$http
            .get('/setting/withdrawscb/delete', { params })
            .then(response => {
              this.getData()
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: '<h3 style="color: #141414">ลบข้อมูลสำเร็จ</h3>',
                  text: '',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.$swal('เกิดข้อผิดพลาด', error.response.data.message, 'error')
            })
        } else {
          this.$swal('ยกเลิก', '', 'error')
        }
      })
    },
    editData(data) {
      this.bankData = data
      this.bankname = data.name
      this.bankcode = data.bankcode
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
